import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { ButtonActionType, UiZoneWidgetAttributeConfig } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { GraphqlServiceService } from '../../../services/graphql-service/graphql-service.service';
import { MetadataService } from '../../../services/metadata-service/metadata-service.service';
import { addEmployeeMethod, inviteEmployeeMethod } from '../add-employee/add-employee-action.utils';
import { functionCtaType } from '../cta-button-actions.utils';
import { assignPersonaUser } from '../manage-users-zone/manage-users-actions.utils';
import { addUserSendInvite } from '../user-management-zone-actions/add-user-send-invite.utils';
import { resetAssignPersonaFormStateAndRedirect } from '../user-management-zone-actions/assign-persona-flow-actions.utils';
import { mangeUseredit } from '../user-management-zone-actions/manage-user-edit.utils';

export const userManagementActions: Map<ButtonActionType, functionCtaType> = new Map([
  [
    ButtonActionType.ASSIGN_PERSONA_FORM_STATE_CLEAR_AND_REDIRECT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      resetAssignPersonaFormStateAndRedirect(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
      );
    },
  ],
  [
    ButtonActionType.ADD_EMPLOYEE_CONFIRM_SAVE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      addEmployeeMethod(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(MetadataService),
        injector.get(GraphqlServiceService),
        attri,
      );
    },
  ],

  [
    ButtonActionType.ADD_EMPLOYEE_INVITE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      inviteEmployeeMethod(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(MetadataService),
      );
    },
  ],
  [
    ButtonActionType.ASSIGN_PERSONA,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      assignPersonaUser(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(MetadataService),
      );
    },
  ],
  [
    ButtonActionType.ADD_USER_SEND_INVITE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      addUserSendInvite(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(MetadataService),
      );
    },
  ],
  [
    ButtonActionType.MANAGE_USER_EDIT_AND_SAVE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      mangeUseredit(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(MetadataService),
      );
    },
  ],
]);
