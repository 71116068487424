import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  ButtonActionType,
  DocumentType,
  UiZoneWidgetAttributeConfig,
} from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { AccountService } from '../../../services/account-service/account-service.service';
import { BulkPaymentService } from '../../../services/bulk-payment-service/bulk-payment-service.service';
import { ConfigService } from '../../../services/config-service/config-service.service';
import { CtaButtonSignalService } from '../../../services/cta-button-signal-service/cta-button-signal.service';
import { FeeManagementService } from '../../../services/fee-management-service/fee-management.service';
import { FormSubmissionService } from '../../../services/form-submission-service/form-submission-service.service';
import { GraphqlServiceService } from '../../../services/graphql-service/graphql-service.service';
import { KeycloakWrapperService } from '../../../services/keycloak-wrapper-service/keycloak-wrapper.service';
import { MetadataService } from '../../../services/metadata-service/metadata-service.service';
import {
  addBeneficiary,
  approveBulkPayment,
  cancelAllPayment,
  downloadCsvTemplate,
  goToPrePayment,
  makePayment,
  proceedBulkPayment,
  proceedCSVFile,
  refreshBulkPayment,
  rejectAllPayment,
} from '../bulk-payments/bulk-payments.utils';
import { functionCtaType } from '../cta-button-actions.utils';
import { downloadBankStatement } from '../profile-zone/download-bank-statement.utils';

export const bulkPaymentsActions: Map<ButtonActionType, functionCtaType> = new Map([
  [
    ButtonActionType.REFRESH_BULK_PAYMENT_LIST,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      refreshBulkPayment(injector.get(Store));
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_BENEFICIARY_VALIDATION,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      goToPrePayment(injector.get(Router));
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_ADD_BENEFICIARY,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      addBeneficiary(
        injector.get(Store),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(AccountService),
      );
    },
  ],
  [
    ButtonActionType.DOWNLOAD_CSV_BANK_STATEMENT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      downloadBankStatement(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Router),
        DocumentType.CSV,
      );
    },
  ],
  [
    ButtonActionType.DOWNLOAD_PDF_BANK_STATEMENT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      downloadBankStatement(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Router),
        DocumentType.PDF,
      );
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_MAKE_PAYMENT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      makePayment(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(MetadataService),
        injector.get(GraphqlServiceService),
        attri,
        injector.get(AccountService),
        injector.get(FormSubmissionService),
        injector.get(ConfigService),
        injector.get(FeeManagementService),
        injector.get(KeycloakWrapperService),
        injector.get(CtaButtonSignalService),
        injector.get(BulkPaymentService),
      );
    },
  ],
  [
    ButtonActionType.DOWNLOAD_CSV,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      downloadCsvTemplate(injector.get(AlertHandlerService));
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_APPROVER,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      approveBulkPayment(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(Actions),
      );
    },
  ],

  [
    ButtonActionType.BULK_PAYMENT_REJECT,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      rejectAllPayment(
        injector.get(Store),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(BulkPaymentService),
      );
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_AUTHENTICATION_LINK,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      proceedBulkPayment(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
      );
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_CANCEL,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      cancelAllPayment(
        injector.get(Store),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(AccountService),
      );
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_DOWNLOAD_UPLOAD_PROCESSING,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      proceedCSVFile(
        injector.get(Store),
        injector.get(AccountService),
        injector.get(Router),
        injector.get(AlertHandlerService),
      );
    },
  ],
]);
