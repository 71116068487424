import { Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { ButtonActionType, UiZoneWidgetAttributeConfig } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { AccountService } from '../../../services/account-service/account-service.service';
import { BackOverrideService } from '../../../services/back-override-service/back-override.service';
import { CtaButtonSignalService } from '../../../services/cta-button-signal-service/cta-button-signal.service';
import { FormSubmissionService } from '../../../services/form-submission-service/form-submission-service.service';
import { GraphqlServiceService } from '../../../services/graphql-service/graphql-service.service';
import { MetadataService } from '../../../services/metadata-service/metadata-service.service';
import { editBeneficiary } from '../beneficiary-zone/edit-beneficiary.utils';
import { setBeneficiaryDataAndRedirectToEditBeneficiaryPage } from '../beneficiary-zone/redirect-to-edit-beneficiary.utils';
import { callRemoveBeneficiaryAPI } from '../beneficiary-zone/remove-beneficiary.utils';
import { callEditIntlBeneficiaryAPI } from '../beneficiary-zone/submit-edit-beneficiary-request.utils';
import { functionCtaType } from '../cta-button-actions.utils';
import { addEditBeneficiary } from '../domestic-payments/add-beneficiary';
import { updateBeneficiaryDetail } from '../domestic-payments/edit-beneficiary';
import { skipAddressRedirection } from '../domestic-payments/payment-flow-redirection';
import { callIntlBeneficiaryAPI } from '../intl-payments/add-international-beneficiary';

export const beneficariesActions: Map<ButtonActionType, functionCtaType> = new Map([
  [
    ButtonActionType.ADD_INTERNATIONAL_BENEFICIARY,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      injector.get(CtaButtonSignalService).setSignal({
        ['add_international_beneficiary']: false,
      });
      callIntlBeneficiaryAPI(
        injector.get(Store),
        injector.get(Router),
        injector.get(AlertHandlerService),
        attri,
        injector.get(AccountService),
        injector.get(FormSubmissionService),
        injector.get(CtaButtonSignalService),
      );
    },
  ],
  [
    ButtonActionType.REDIRECT_TO_EDIT_BENEFICIARY,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      setBeneficiaryDataAndRedirectToEditBeneficiaryPage(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
      );
    },
  ],
  [
    ButtonActionType.SUBMIT_EDIT_BENEFICIARY,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      callEditIntlBeneficiaryAPI(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(AccountService),
      );
    },
  ],
  [
    ButtonActionType.ADD_BENEFICIARY,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      addEditBeneficiary(
        injector.get(Store),
        injector.get(Router),
        injector.get(AccountService),
        injector.get(ActivatedRoute),
        injector.get(AlertHandlerService),
        injector.get(BackOverrideService),
      );
    },
  ],
  [
    ButtonActionType.UPDATE_BENEFICIARY_DETAILS,
    (injector: Injector) => {
      updateBeneficiaryDetail(injector.get(Store), injector.get(Router), injector.get(AccountService));
    },
  ],
  [
    ButtonActionType.REMOVE_BENEFICIARY,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      callRemoveBeneficiaryAPI(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(MetadataService),
        injector.get(GraphqlServiceService),
        attri,
        injector.get(AccountService),
      );
    },
  ],
  [
    ButtonActionType.EDIT_BENEFICIARY,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      editBeneficiary(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
      );
    },
  ],
  [
    ButtonActionType.SKIP_BENEFICIARY_ADDRESS,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      skipAddressRedirection(injector.get(Router), attri.widgetProperties);
    },
  ],
]);
