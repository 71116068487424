export const confirmBtnTemplate = `Confirm`;

export const beneficiaryDetailsTemplate = `
<div class="w-100 h-100">
  <div class="d-flex justify-content-between w-100">
    <div class="labelText textOnSurfaceMuted">Full Name</div>
    <div class="body2 text-right textOnSurface">{{ beneficiaryDetails.name }}</div>
  </div>
  <hr class="my-4">

  {{#if beneficiaryDetails.accountNumber}}
    <div class="d-flex justify-content-between w-100 mb-4">
      <div class="labelText textOnSurfaceMuted">Account Number</div>
      <div class="body1 text-right textOnSurface">{{ beneficiaryDetails.accountNumber }}</div>
    </div>
  {{/if}}

  {{#if beneficiaryDetails.sortCode}}
    <div class="d-flex justify-content-between w-100 mb-4">
      <div class="labelText textOnSurfaceMuted">Sort Code</div>
      <div class="body1 text-right textOnSurface">{{{ addDashesToSortCode beneficiaryDetails.sortCode }}}</div>
    </div>
  {{/if}}

  {{#if beneficiaryDetails.accountNumber}}
    <hr class="my-4">
  {{else if beneficiaryDetails.sortCode}}
    <hr class="my-4">
  {{/if}}

  {{#if beneficiaryDetails.currency}}
    <div class="d-flex justify-content-between w-100 mb-4">
      <div class="labelText textOnSurfaceMuted">Currency</div>
      <div class="body1 text-right textOnSurface">{{ beneficiaryDetails.currency }}</div>
    </div>
    <hr class="my-4">
  {{/if}}

  {{#if beneficiaryDetails.iban}}
    <div class="d-flex justify-content-between w-100 mb-4">
      <div class="labelText textOnSurfaceMuted">IBAN</div>
      <div class="body1 text-right textOnSurface">{{ beneficiaryDetails.iban }}</div>
    </div>
  {{/if}}

  {{#if beneficiaryDetails.bic}}
    <div class="d-flex justify-content-between w-100 mb-4">
      <div class="labelText textOnSurfaceMuted">BIC</div>
      <div class="body1 text-right textOnSurface text-capitalize">{{ beneficiaryDetails.bic }}</div>
    </div>
  {{/if}}

  {{#if beneficiaryDetails.iban}}
    <hr class="my-4">
  {{else if beneficiaryDetails.bic}}
    <hr class="my-4">
  {{/if}}

  {{#if beneficiaryDetails.addressLine1}}
    <div class="d-flex justify-content-between w-100 mb-4">
      <div class="labelText textOnSurfaceMuted">Street</div>
      <div class="body1 text-right textOnSurface">{{ beneficiaryDetails.addressLine1 }}</div>
    </div>
  {{/if}}

  {{#if beneficiaryDetails.addressCity}}
    <div class="d-flex justify-content-between w-100 mb-4">
      <div class="labelText textOnSurfaceMuted">City</div>
      <div class="body1 text-right textOnSurface">{{ beneficiaryDetails.addressCity }}</div>
    </div>
  {{/if}}

  {{#if beneficiaryDetails.addressState}}
    <div class="d-flex justify-content-between w-100 mb-4">
      <div class="labelText textOnSurfaceMuted">State/Region</div>
      <div class="body1 text-right textOnSurface">{{ beneficiaryDetails.addressState }}</div>
    </div>
  {{/if}}

  {{#if beneficiaryDetails.addressCountry}}
    <div class="d-flex justify-content-between w-100 mb-4">
      <div class="labelText textOnSurfaceMuted">Country</div>
      <div class="body1 text-right textOnSurface">{{countryFullName beneficiaryDetails.addressCountry }}</div>
    </div>
  {{/if}}

  {{#if beneficiaryDetails.addressPostCode}}
    <div class="d-flex justify-content-between w-100 mb-4">
      <div class="labelText textOnSurfaceMuted">ZIP/Postal Code</div>
      <div class="body1 text-right textOnSurface">{{ beneficiaryDetails.addressPostCode }}</div>
    </div>
  {{/if}}
</div>
`;

export const suggestedBankBeneficiaryDetailsTemplate = `<div class="w-100 h-100">
<div class='is-vertical-align' style='align-items: flex-start;'>
    <div class="ben-label">Full Name</div>
    <div class="ben-value">{{ beneficiaryDetails.name }}</div>
</div>
<hr class="my-4 mx-1">
<div class="bank-info">
    <div class='is-vertical-align' style='padding-right: 66px; justify-content: space-between;'>
        <div class='is-vertical-align align-left'>
            <div class="ben-label">Sort Code</div>
            <div class="ben-value">{{ addDashesToSortCode beneficiaryDetails.sortCode}}</div>
        </div>
    </div>
    <div class='is-vertical-align align-left'>
        <div class="ben-label" >Account Number</div>
        <div class="ben-value">{{ beneficiaryDetails.accountNumber }}</div>
    </div>
</div>
</div>`;

export const copEnteredDetails = `
<div class="w-100">
    <div class="font-weight-medium">
        <div class="is-left mb-3">
            <div class="is-vertical-align">
                <div class="typog-sm is-left" style="color: var(--typography-color-1)">
                    {{#if (eq beneficiaryDetails.accountType 'personal')}}Account Holder{{else}}Corporate{{/if}} Name
                </div>
                <div class="typog-md is-left">
                    {{ beneficiaryDetails.name }}
                </div>
            </div> 
        </div>
        <div class="is-left mb-3">
            <div class="is-vertical-align">
                <div class="typog-sm" style="color: var(--typography-color-1)">
                    Account Number
                </div>
                <div class="typog-md is-left">
                    {{ beneficiaryDetails.accountNumber }}  
                </div>
            </div>
        </div>  
        <div class="is-left">
            <div class="is-vertical-align">
                <div class="typog-sm is-left" style="color: var(--typography-color-1)">
                    Sort Code
                </div>
            <div class="typog-md is-left">
                {{ addDashesToSortCode beneficiaryDetails.sortCode }}
            </div>
        </div>  
    </div>
</div>`;

export const copCloseMatchDetails = `<div class="w-100">
              <div class="is-center typog-md mb-2"><b>You entered:</b></div>
              <div class="font-weight-medium">
               <div class="is-left mb-3">
                <div class="mt-2">
		{{#if (eq copResponse.matchStatusCode 'close_match')}}
    {{{imageFromImageName 'icon-library/material' 'mat_success_check_circle_outline' '20px'}}}

		{{else}}
                  {{{imageFromImageName 'icon-library/material' 'mat_error' '20px'}}}
		{{/if}}
                </div>
                <div class="is-vertical-align ml-2">
                  <div class="typog-sm is-left" style="color: var(--typography-color-1)">Account Type</div>
                  <div class="typog-md is-left">
                    {{'capitaliseFirstLetter' beneficiaryDetails.accountType }}
                  </div>
                </div> 
                </div>
                <div class="is-left mb-3">
                <div class="mt-2">
		{{#if (eq copResponse.matchStatusCode 'name_matched')}}
    {{{imageFromImageName 'icon-library/material' 'mat_success_check_circle_outline' '20px'}}}

		{{else}}
                  {{{imageFromImageName 'icon-library/material' 'mat_error' '20px'}}}
		{{/if}}
                </div>
                <div class="is-vertical-align ml-2">
                  <div class="typog-sm is-left" style="color: var(--typography-color-1)">{{#if (eq beneficiaryDetails.accountType 'personal')}}Account Holder{{else}}Corporate{{/if}} Name</div>
                  <div class="typog-md is-left">
                    {{ beneficiaryDetails.name }}
                  </div>
                </div> 
                </div>
                <div class="is-left mb-3">
                  <div class="mt-2">
                    {{{imageFromImageName 'icon-library/material' 'mat_success_check_circle_outline' '20px'}}}
                  </div>
                  <div class="is-vertical-align ml-2">
                    <div class="typog-sm" style="color: var(--typography-color-1)">Account Number</div>
                    <div class="typog-md is-left">
                      {{ beneficiaryDetails.accountNumber }}  
                    </div>
                  </div>
                </div>  
                <div class="is-left">
                  <div class="mt-2">
                    {{{imageFromImageName 'icon-library/material' 'mat_success_check_circle_outline' '20px'}}}
                  </div>
                  <div class="is-vertical-align ml-2">
                    <div class="typog-sm is-left" style="color: var(--typography-color-1)">Sort Code</div>
                    <div class="typog-md is-left">{{ addDashesToSortCode beneficiaryDetails.sortCode }}
                    </div>
                  </div>  
              </div>
            </div></div>`;

export const copCloseMatchReturnedBankName = `{{#if (eq copResponse.matchStatusCode 'name_matched')}}
              You submitted this beneficiary as a 
              {{#if (eq beneficiaryDetails.accountType 'personal')}}Personal{{else}}Business{{/if}} account, but their bank told us they are a {{#if (eq beneficiaryDetails.accountType 'personal')}}Business{{else}}Personal{{/if}} account.{{/if}}
              {{#if (eq copResponse.matchStatusCode 'close_match')}}
              <div class="w-100"><div class="is-center">Their bank told us:</div>
<div class="is-vertical-align align-items-flex-start">
<div class="typog-sm mt-2" style="color: var(--typography-color-1)">{{#if (eq beneficiaryDetails.accountType 'personal')}}Account Holder{{else}}Corporate{{/if}} Name</div><div>{{ copResponse.name }}</div>
</div>
</div>
{{/if}}
{{#if (eq copResponse.matchStatusCode 'account_type_mismatch_name_close_match')}}
This is a {{#if (eq beneficiaryDetails.accountType 'personal')}}Business{{else}}Personal{{/if}} account not a {{#if (eq beneficiaryDetails.accountType 'personal')}}Personal{{else}}Business{{/if}} account, also the name returned by the bank is {{ copResponse.name }}
{{/if}}`;

export const copNoMatchDetails = `<div class="w-100">
              <div class="is-center typog-md mb-2"><b>You entered:</b></div>
              {{#if (neq copResponse.matchStatusCode 'invalid-secondary-identifier')}}
<div class="font-weight-medium">
                <div class="is-left mb-3">

                <div class="is-vertical-align">
                  <div class="typog-sm is-left" style="color: var(--typography-color-1)">{{#if (eq beneficiaryDetails.accountType 'personal')}}Account Holder{{else}}Corporate{{/if}} Name</div>
                  <div class="typog-md is-left">
                    {{ beneficiaryDetails.name }}
                  </div>
                </div> 
                </div>
                <div class="is-left mb-3">
                  <div class="is-vertical-align">
                    <div class="typog-sm is-left" style="color: var(--typography-color-1)">Account Number</div>
                    <div class="typog-md is-left">
                      {{ beneficiaryDetails.accountNumber }}  
                    </div>
                  </div>
                </div>  
                <div class="is-left">
                  <div class="is-vertical-align">
                    <div class="typog-sm is-left" style="color: var(--typography-color-1)">Sort Code</div>
                    <div class="typog-md is-left">{{ addDashesToSortCode beneficiaryDetails.sortCode }}
                    </div>
                  </div>  
              </div>
              {{else}}
              <div class="font-weight-medium">
                <div class="is-left mb-1">
                <div class="is-vertical-align">
                  <div class="typog-xs is-left" style="color: var(--typography-color-1)">{{#if (eq beneficiaryDetails.accountType 'personal')}}Account Holder{{else}}Corporate{{/if}} Name</div>
                  <div class="typog-sm is-left">
                    {{ beneficiaryDetails.name }}
                  </div>
                </div> 
                </div>
                <div class="is-left mb-1">
                  <div class="is-vertical-align">
                    <div class="typog-xs is-left" style="color: var(--typography-color-1)">Account Number</div>
                    <div class="typog-sm is-left">
                      {{ beneficiaryDetails.accountNumber }}  
                    </div>
                  </div>
                </div>  
                <div class="is-left mb-1">
                  <div class="is-vertical-align">
                    <div class="typog-xs is-left" style="color: var(--typography-color-1)">Sort Code</div>
                    <div class="typog-sm is-left">{{ addDashesToSortCode beneficiaryDetails.sortCode }}
                    </div>
                </div>
                </div>
                <div class="is-left">
                  <div class="is-vertical-align">
                    <div class="typog-xs is-left" style="color: var(--typography-color-1)">Secondary Identifier</div>
                    <div class="typog-sm is-left">{{ beneficiaryDetails.srd }}
                    </div>
                </div> 
                </div> 
              </div>
              {{/if}}
              
            </div></div>`;
