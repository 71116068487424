export enum ACCOUNTTYPE {
  PERSONAL = 'personal',
  BUSINESS = 'business',
}

export enum AccountStatusType {
  active = 'active',
  closed = 'closed',
  inactive = 'inactive',
  suspended = 'suspended',
  declined = 'declined',
  pending = 'pending',
  error = 'error',
}

export enum ClientStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum AutoCardStatus {
  PHYSICAL = 'physical',
  VIRTUAL = 'virtual',
  NOT_ENABLED = ' not_enabled',
}

export enum CheckPayeeMatchStatus {
  MATCHED = 'matched',
  NOT_MATCHED = 'not_matched',
  CLOSE_MATCH = 'close_match',
  NAME_MATCHED = 'name_matched',
  ACCOUNT_TYPE_MISMATCH_NAME_CLOSE_MATCH = 'account_type_mismatch_name_close_match',
  INVALID_SECONDARY_IDENTIFIER = 'invalid-secondary-identifier',
  ACCOUNT_DOES_NOT_EXIST = 'account_does_not_exist',
  ACCOUNT_NOT_SUPPORTED = 'account_not_supported',
  SORT_CODE_NOT_SUPPORTED = 'sort_code_not_supported',
  ACCOUNT_NUMBER_INCORRECT = 'account_number_incorrect',
  OPTED_OUT = 'opted_out',
  ACCOUNT_SWITCHED = 'account_switched',
  WRONG_PARTICIPANT = 'wrong_participant',
  NO_RESPONSE = 'no_response',
  NOT_ENROLLED = 'not_enrolled',
  NOT_CHECKED = 'not_checked',
}

export enum BulkPaymentBeneficiaryCoPCodes {
  NOT_REQUIRED = 'not_required',
}

export type BulkPaymentBeneficiaryCoPCodesType = BulkPaymentBeneficiaryCoPCodes | CheckPayeeMatchStatus;

export enum HeaderChannelStatus {
  WEB = 'web',
  MOBILE = 'mobile',
}

export enum IndividualTransactionType {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
}

export enum ClientType {
  INDIVIDUAL = 'individual',
  CORPORATE = 'corporate',
}

export enum ProviderTransactionType {
  OUTGOING = 'outgoing',
  INCOMING = 'incoming',
  GENERIC = 'generic',
}
